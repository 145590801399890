/*
 * @Author: Do not edit
 * @Date: 2020-07-22 10:34:01
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-02-29 11:46:10
 * @Description: Do not edit
 * @FilePath: \user\src\api\login\LoginService.js
 */
import BaseService from "../BaseService";

/**
 * login相关
 */
class LoginService extends BaseService {
  pk = "";

  constructor(http) {
    super("", http);
  }

  /**
   * 登录
   * @param params 登录参数
   * @returns {*}
   */
  login(params) {
    const { http } = this;
    sessionStorage.setItem("userPhone", params.userName);
    return http.post("auth/login", {
      ...params,
      pageType: 2
    });
  }

  /**
   * 获取验证码
   * @param params 参数
   * @returns {*}
   */
  getValidateCode(params = {}) {
    return this.http.post("getValidateCode", { ...params, requestType: "stream" });
  }

  /**
   * 获取验证码
   * @param params 参数
   * @returns {*}
   */
  getUserInfo(params) {
    return this.http.post("getUserInfo", params);
  }

  /**
   * 发送校验码
   * @param params 参数
   */
  sendCode(params) {
    const { http } = this;
    return http.post("sendValidateCode", params);
  }

  /**
   * 检查校验码
   * @param params
   */
  checkCode(params) {
    const { http } = this;
    return http.post("checkValidateCode", params);
  }

  /**
   * 重置密码
   * @param params
   * @returns {*}
   */
  forgetPassword(params) {
    const { http } = this;
    return http.post("forgetPassword", params);
  }

  /**
   * 二维码登录
   * @param params
   * @returns {*}
   */
  getLoginQrCode(params) {
    // const { http } = this;
    return this.query("getLoginQrCode", params);
    // return http.get("getLoginQrCode", { params });
  }

  /**
   * @description:判断是否 设置过查看工资条密码
   * @param {*} params
   * @return {*}
   */
  loginCheckPs(params) {
    const { http } = this;
    return http.post("loginCheckPs", params);
  }

  /**
   * @description: 查找企业平台设置
   * @param {*} params
   * @return {*}
   */
  clCorpQuery(params) {
    const { http } = this;
    return this.query("clCorpQuery", params);
    // return http.post("clCorpQuery", params);
  }

  /**
   * @description: 查找企业平台设置
   * @param {*} params
   * @return {*}
   */
 async clChannelSetQuery(params) {
    // const { http } = this;
    const data = await this.post("clChannelSetQuery", params);
    if (data) {
      if (data.abnormalFlag === 0) {
        // sessionStorage.setItem('abnormalImg',data.abnormalSysImg)
    } else if (data.abnormalImgUrl) {
            sessionStorage.setItem("abnormalImg", data.abnormalImgUrl);
        }

    if (data.datadefaultFlag === 0) {
        // sessionStorage.setItem('datadefaultImg',data.datadefaultImg)
    } else if (data.datadefaultImgUrl) {
            sessionStorage.setItem("datadefaultImg", data.datadefaultImgUrl);
        }

    if (data.dataloadingFlag === 0) {
        // sessionStorage.setItem('dataloadingImg',data.dataloadingImg)
    } else if (data.dataloadingImgUrl) {
            sessionStorage.setItem("dataloadingImg", data.dataloadingImgUrl);
        }
    }

    return data;
  }

  authLogin(params) {
    const { http } = this;
    return http.post("auth/sso/login", params);
  }
}

export default LoginService;
