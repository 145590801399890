/**
 * @Author 李煜
 * @Date 2020/6/16
 * @Description login模块
 */
const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import(/* webpackChunkName: "login" */ "@/views/login/index"),
    meta: {
      title: "登录",
      fullPage: true
    }
  },
  {
    path: "/autoLogin",
    name: "autoLogin",
    component: () => import(/* webpackChunkName: "login" */ "@/views/autoLogin/index"),
    meta: {
      title: "登录",
      fullPage: true
    }
  },
  {
    path: "/register",
    name: "register",
    component: () => import(/* webpackChunkName: "login" */ "@/views/login/register"),
    meta: {
      title: "注册",
      fullPage: true
    }
  }
];

export default routes;
