/*
 * @Author: zhangjiaheng zhangjiaheng@enfry.com
 * @Date: 2020-06-10 16:19:23
 * @LastEditors: zhangjiaheng zhangjiaheng@enfry.com
 * @LastEditTime: 2022-08-03 13:51:35
 * @FilePath: \user\src\router\permission.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import NProgress from "nprogress";
import PayloginBox from "@/components/paylogin-box";
import { reLogin } from "@/api/tools";
import store from "../store";
import "nprogress/nprogress.css";

const whiteList = ["payrollCheck", "paystubNav"];// 免登录白名单  用于邮件查看工资
function needHrLogin(to) {
  const curPid = sessionStorage.getItem("curPageId") || "";
  if (to.path.indexOf("payroll") === -1) return false; // 如果不是跳转工资相关路由无需验证
  if (curPid.indexOf("payroll") > -1) return false; // 刷新的情况下无需验证
  if (to.meta.noVerify) return false;
  return true;
}
/**
 * @Author 李煜
 * @Date 2020/6/5
 * @Description router权限钩子
 */
export default {
  beforeEach: (to, from, next) => {
    if (to.name !== "businessDetail") {
      console.log(from, "from");
      console.log(to, "to");

      if (!(["toBusinessList", "businessList"].includes(to.name) && (from.query.id === to.query.templateId || from.query.templateId === to.query.templateId))) {
        sessionStorage.removeItem("business_condition");
      }
    }
    NProgress.start();
    const appSessionId = localStorage.getItem("appSessionId");
    // 如果 是特殊数据 直接走登录页 如果跳转到登录页 重新走登录 zjh
    if ((to.name === "login" || to.name === "autoLogin") && to.fullPath.indexOf("account") > -1) {
      next();
      return;
    }
    if (to.name !== "login" && to.name !== "autoLogin" && !appSessionId && !whiteList.includes(to.name)) {
      window.open(`/user/login?redirect=${encodeURIComponent(location.href)}`, "_self");
    }

    // 已登录状态禁止返回到登录页面
    if (appSessionId && appSessionId.length > 0 && to.name === "login" && to.name === "autoLogin" && !to.query.appSessionId) {
      // 存在则不让返回登录页面,直接返回首页
      // 大屏展示特殊处理 开始
      // 特殊的 手机号 在刷新页面 或者 路由进入login 是 判断出 已登录，直接跳转到 对应的 大屏展示页面
      const mobileNoLi = ["15000000013", "15000000011", "15000000012"];
      if (store?.state?.userInfo && mobileNoLi.indexOf(store.state.userInfo.mobileNo) > -1) {
        // 如果存在用户信息 如果是 特殊的手机号 进行特殊处理 直接跳转到
        window.localStorage.setItem("autoShow", "2");
        if (window.location.hostname === "en2.enfry.com" || window.location.hostname === "en3.enfry.com") {
          // 副屏
          next({ path: "/indexVice" });
          window.localStorage.setItem("sameScreenAuthType", "indexVice");
        } else {
          // 主屏
          next({ path: "/indexFull" });
          window.localStorage.setItem("sameScreenAuthType", "indexFull");
        }
        return;
      }
      // 大屏展示特殊处理 结束
      next("/home/index");
      return;
    }
    if (needHrLogin(to, from)) {
      // debugger;
      console.log("from", from.matched);
      // to.name
      NProgress.done();
      PayloginBox({
        userInfo: store?.state?.userInfo

      }).then((res) => {
        NProgress.start();
        if (res.data === "success") {
          next();
        } else if (res.data === "needLogin") {
          reLogin("由于您多次输入错误密码，1s后跳转到登录页面", 1);
        }
      }).catch((err) => {
        console.log("err", err);
        if (!from.path) { next("/"); }// 页面刷新的情况下跳转首页
        NProgress.done();
      });
    } else {
      next();
    }
  },
  afterEach: (to) => {
    sessionStorage.setItem("curPageId", to.path);// 缓存上一个页面的路径信息，用于手动刷新页面后获取刷新前的页面路由
    NProgress.done();
  }
};
