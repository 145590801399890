var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "top-nav-menu-container", style: _vm.navStyle },
    [
      _c("div", { staticStyle: { display: "flex", width: "600px" } }, [
        _c("div", { staticStyle: { width: "188px" } }, [
          _c("div", { staticClass: "bannerStyle", style: _vm.bannerStyles }),
          _c(
            "div",
            {
              staticClass: "logo",
              staticStyle: { "z-index": "2", position: "relative" },
              on: { click: _vm.backHome },
            },
            [
              !_vm.getFileUrl(_vm.componyMeta.loginLogo)
                ? _c("img", {
                    staticClass: "src",
                    style: _vm.logoStyle,
                    attrs: {
                      src: require("@/assets/images/enlogo.png"),
                      alt: "",
                    },
                  })
                : _c("img", {
                    attrs: {
                      src: _vm.getFileUrl(_vm.componyMeta.loginLogo),
                      alt: "",
                    },
                  }),
            ]
          ),
        ]),
        _c(
          "div",
          {
            staticStyle: {
              flex: "1",
              display: "flex",
              "align-items": "center",
            },
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  width: "100%",
                  display: "flex",
                  "align-items": "center",
                },
              },
              [
                _c(
                  "el-popover",
                  {
                    attrs: {
                      placement: "bottom-start",
                      width: "600",
                      trigger: "manual",
                    },
                    on: { show: _vm.getSearchhistory },
                    model: {
                      value: _vm.showpopover,
                      callback: function ($$v) {
                        _vm.showpopover = $$v
                      },
                      expression: "showpopover",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "search-history" }, [
                          _c("div", { staticClass: "search-history-title" }, [
                            _c("span", { staticClass: "title" }, [
                              _vm._v("搜索历史"),
                            ]),
                            _c("img", {
                              staticClass: "searchImg histort-del",
                              attrs: {
                                src: require("@/assets/images/deletehistory.png"),
                                id: "history-del-search",
                              },
                              on: { click: _vm.clearHistory },
                            }),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "search-history-content",
                              attrs: { id: "search-history-content-values" },
                            },
                            _vm._l(_vm.glbhistory, function (item) {
                              return _c(
                                "span",
                                {
                                  key: item.id,
                                  staticClass: "history-valitem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.searchhistory(item)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(item))]
                              )
                            }),
                            0
                          ),
                        ]),
                        _c(
                          "el-tabs",
                          {
                            on: { "tab-click": _vm.changeTab },
                            model: {
                              value: _vm.activeName,
                              callback: function ($$v) {
                                _vm.activeName = $$v
                              },
                              expression: "activeName",
                            },
                          },
                          [
                            _c("el-tab-pane", {
                              attrs: { label: "综合", name: "1" },
                            }),
                            _c("el-tab-pane", {
                              attrs: { label: "功能", name: "2" },
                            }),
                            _c("el-tab-pane", {
                              attrs: { label: "帮助中心", name: "3" },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "search-group" }, [
                          _vm.searchValuesShowList.length > 0
                            ? _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "search-detail",
                                    attrs: { id: "search-detail-value-list" },
                                  },
                                  _vm._l(
                                    _vm.searchValuesShowList,
                                    function (item) {
                                      return _c(
                                        "div",
                                        {
                                          key: item.id,
                                          staticClass:
                                            "search-detail-value-item",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "search-field" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-right": "10px",
                                                    width: "40px",
                                                  },
                                                },
                                                [
                                                  item?.menudata?.customIcon
                                                    ? _c("en-icon", {
                                                        class: {
                                                          "en-icon": true,
                                                        },
                                                        style: {
                                                          color:
                                                            item.menudata
                                                              .customColor,
                                                        },
                                                        attrs: {
                                                          name: _vm.setChildrenIcon(
                                                            item.menudata
                                                              .customIcon
                                                          ),
                                                          size: "30",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _c("div", [
                                                _c("p", {
                                                  staticClass:
                                                    "search-field-value",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.replacetit(item.name)
                                                    ),
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.goto(item)
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "search-field-value-desc",
                                                  },
                                                  [
                                                    item?.classifyRefName
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.recordType ===
                                                                "2"
                                                                ? "功能"
                                                                : "帮助文档"
                                                            ) +
                                                              "：" +
                                                              _vm._s(
                                                                item?.classifyRefName
                                                              )
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    item?.classifyRefName &&
                                                    item.accessTime
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "shuxian",
                                                            staticStyle: {
                                                              margin: "0 5px",
                                                            },
                                                          },
                                                          [_vm._v("|")]
                                                        )
                                                      : _vm._e(),
                                                    item.accessTime
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "search-field-value-desc-split",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "你在" +
                                                                _vm._s(
                                                                  item.accessTime
                                                                ) +
                                                                " 打开过"
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                                _vm.isshowmore
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "search-more",
                                        staticStyle: { cursor: "pointer" },
                                        attrs: { id: "search-more-value" },
                                        on: { click: _vm.showMore },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-arrow-down searchicon",
                                        }),
                                        _vm._v(" 展开更多 "),
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      {
                                        staticClass: "search-more",
                                        staticStyle: { cursor: "pointer" },
                                        attrs: { id: "search-more-value" },
                                      },
                                      [_vm._v(" 已经到底啦~ ")]
                                    ),
                              ])
                            : _c("div", { staticClass: "nodata-empty" }, [
                                _vm._v(" 暂无数据 "),
                              ]),
                        ]),
                        _vm.searchBusinessValuesShowList.length > 0
                          ? _c("div", { staticClass: "search-group" }, [
                              _c("p", { staticClass: "group-name" }, [
                                _vm._v("功能"),
                              ]),
                              _vm.searchBusinessValuesShowList.length > 0
                                ? _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "search-detail",
                                        attrs: {
                                          id: "search-detail-value-list",
                                        },
                                      },
                                      _vm._l(
                                        _vm.searchBusinessValuesShowList,
                                        function (item) {
                                          return _c(
                                            "div",
                                            {
                                              key: item.id,
                                              staticClass:
                                                "search-detail-value-item",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "search-field" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "margin-right": "10px",
                                                        width: "40px",
                                                      },
                                                    },
                                                    [
                                                      item?.menudata?.customIcon
                                                        ? _c("en-icon", {
                                                            class: {
                                                              "en-icon": true,
                                                            },
                                                            style: {
                                                              color:
                                                                item.menudata
                                                                  .customColor,
                                                            },
                                                            attrs: {
                                                              name: _vm.setChildrenIcon(
                                                                item.menudata
                                                                  .customIcon
                                                              ),
                                                              size: "30",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                  _c("div", [
                                                    _c("p", {
                                                      staticClass:
                                                        "search-field-value",
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.replacetit(
                                                            item.name
                                                          )
                                                        ),
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.goto(item)
                                                        },
                                                      },
                                                    }),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "search-field-value-desc",
                                                      },
                                                      [
                                                        item?.classifyRefName
                                                          ? _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.recordType ===
                                                                    "2"
                                                                    ? "功能"
                                                                    : "帮助文档"
                                                                ) +
                                                                  "：" +
                                                                  _vm._s(
                                                                    item?.classifyRefName
                                                                  )
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                        item?.classifyRefName &&
                                                        item.accessTime
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "shuxian",
                                                                staticStyle: {
                                                                  margin:
                                                                    "0 5px",
                                                                },
                                                              },
                                                              [_vm._v("|")]
                                                            )
                                                          : _vm._e(),
                                                        item.accessTime
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "search-field-value-desc-split",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "你在" +
                                                                    _vm._s(
                                                                      item.accessTime
                                                                    ) +
                                                                    " 打开过"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "search-more",
                                        staticStyle: { cursor: "pointer" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.showMorebusiness("2")
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-search searchicon",
                                        }),
                                        _vm._v("在功能中搜索更多 "),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _vm.searchHelpValuesShowList.length > 0
                          ? _c("div", { staticClass: "search-group" }, [
                              _c("p", { staticClass: "group-name" }, [
                                _vm._v("帮助中心"),
                              ]),
                              _vm.searchHelpValuesShowList.length > 0
                                ? _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "search-detail",
                                        attrs: {
                                          id: "search-detail-value-list",
                                        },
                                      },
                                      _vm._l(
                                        _vm.searchHelpValuesShowList,
                                        function (item) {
                                          return _c(
                                            "div",
                                            {
                                              key: item.id,
                                              staticClass:
                                                "search-detail-value-item",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "search-field" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "margin-right": "10px",
                                                        width: "40px",
                                                      },
                                                    },
                                                    [
                                                      item?.menudata?.customIcon
                                                        ? _c("en-icon", {
                                                            class: {
                                                              "en-icon": true,
                                                            },
                                                            style: {
                                                              color:
                                                                item.menudata
                                                                  .customColor,
                                                            },
                                                            attrs: {
                                                              name: _vm.setChildrenIcon(
                                                                item.menudata
                                                                  .customIcon
                                                              ),
                                                              size: "30",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                  _c("div", [
                                                    _c("p", {
                                                      staticClass:
                                                        "search-field-value",
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.replacetit(
                                                            item.name
                                                          )
                                                        ),
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.goto(item)
                                                        },
                                                      },
                                                    }),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "search-field-value-desc",
                                                      },
                                                      [
                                                        item?.classifyRefName
                                                          ? _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.recordType ===
                                                                    "2"
                                                                    ? "功能"
                                                                    : "帮助文档"
                                                                ) +
                                                                  "：" +
                                                                  _vm._s(
                                                                    item?.classifyRefName
                                                                  )
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                        item?.classifyRefName &&
                                                        item.accessTime
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "shuxian",
                                                                staticStyle: {
                                                                  margin:
                                                                    "0 5px",
                                                                },
                                                              },
                                                              [_vm._v("|")]
                                                            )
                                                          : _vm._e(),
                                                        item.accessTime
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "search-field-value-desc-split",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "你在" +
                                                                    _vm._s(
                                                                      item.accessTime
                                                                    ) +
                                                                    " 打开过"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "search-more",
                                        staticStyle: { cursor: "pointer" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.showMorebusiness("3")
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-search searchicon",
                                        }),
                                        _vm._v("在文档中搜索更多 "),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "search-container",
                        attrs: { slot: "reference" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                        slot: "reference",
                      },
                      [
                        _c("el-input", {
                          staticClass: "input-with-select",
                          attrs: { placeholder: "请输入内容" },
                          on: { focus: _vm.showpop },
                          nativeOn: {
                            keyup: function ($event) {
                              return _vm.searchKeyup.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.searchdata.keywords,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchdata, "keywords", $$v)
                            },
                            expression: "searchdata.keywords",
                          },
                        }),
                        _c(
                          "en-button",
                          {
                            staticStyle: { "min-width": "40px" },
                            attrs: { type: "primary" },
                            on: { click: _vm.search },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-search searchicon",
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "click-area" },
        [
          _vm.checkedSameScreen
            ? _c(
                "span",
                {
                  staticClass: "back-version-btn",
                  staticStyle: { background: "#4694df" },
                  on: { click: _vm.openFull },
                },
                [_vm._v("演示")]
              )
            : _vm._e(),
          _c(
            "span",
            {
              class: { "red-star": _vm.showNum > 0 },
              attrs: { title: "公司圈" },
            },
            [
              _c("en-button", {
                staticClass: "msg",
                attrs: {
                  icon: "icongongsiquan",
                  "icon-color": _vm.topIconColor ? _vm.topIconColor : "#26C393",
                  type: "text",
                  "icon-size": "18",
                },
                on: { click: _vm.goCompanyCircle },
              }),
            ],
            1
          ),
          _c(
            "el-badge",
            {
              staticClass: "msg-wrap",
              attrs: { value: _vm.unreadNum, max: 99, hidden: !_vm.unreadNum },
            },
            [
              _c("en-button", {
                staticClass: "msg",
                attrs: {
                  title: "沟通",
                  icon: "icongoutong-shouye",
                  "icon-color": _vm.topIconColor ? _vm.topIconColor : "#4694df",
                  type: "text",
                  "icon-size": "17",
                },
                on: { click: _vm.handleClickChat },
              }),
            ],
            1
          ),
          _c(
            "el-badge",
            {
              staticClass: "mail",
              attrs: {
                value: _vm.unreadMailNum,
                max: 99,
                hidden: !_vm.unreadMailNum,
              },
            },
            [
              _c("en-button", {
                attrs: {
                  title: "邮箱",
                  icon: "icongerenyouxiang",
                  "icon-color": _vm.topIconColor ? _vm.topIconColor : "#4694df",
                  type: "text",
                  "icon-size": "18",
                },
                on: { click: _vm.jumpToMail },
              }),
            ],
            1
          ),
          _c("en-button", {
            staticClass: "theme",
            attrs: {
              title: "主题",
              icon: "iconzhuti",
              "icon-color": _vm.topIconColor ? _vm.topIconColor : "#9367EB",
              type: "text",
              "icon-size": "18",
            },
            on: {
              click: function ($event) {
                _vm.themeSettingVisible = true
              },
            },
          }),
          _c("el-divider", {
            staticClass: "divider-line",
            style: _vm.lineStyles,
            attrs: { direction: "vertical" },
          }),
          _c(
            "div",
            { staticClass: "platform", on: { click: _vm.onRightClick } },
            [
              _c("en-user-logo", {
                staticClass: "logo",
                attrs: {
                  "image-url": _vm.userLogo,
                  "user-name": _vm.storedUserInfo.name,
                  size: "30px",
                },
              }),
              _c(
                "span",
                {
                  staticClass: "name",
                  style: {
                    color: _vm.topIconColor ? _vm.topIconColor : "#1E1E1E",
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.storedUserInfo.name +
                        " " +
                        _vm.storedUserInfo.depatementName
                    )
                  ),
                ]
              ),
              _c("en-icon", {
                staticClass: "point",
                style: {
                  fill: _vm.topIconColor ? _vm.topIconColor : "#A9B5C6",
                },
                attrs: { name: "zhankai", size: "10px" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.switchRoleStatus
        ? _c("switch-role-model", {
            on: {
              closeModelFn: function ($event) {
                _vm.switchRoleStatus = false
              },
              change: _vm.change,
            },
          })
        : _vm._e(),
      _c("theme-setting", {
        attrs: { visible: _vm.themeSettingVisible },
        on: {
          close: function ($event) {
            _vm.themeSettingVisible = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }